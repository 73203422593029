.App {
  text-align: center;
  flex-direction: column;
  display: flex;
}

.scene {
  flex: 3;
  background-size: cover;
  background-position: center center;
}

.story-text {
  /* flex: 1; */
  /* padding: 1em; */
  /* height: 300px;
  padding-bottom: 200px;
  overflow-y: auto;
  overflow-x: hidden; */
}

.story-text p div {
  text-align: left;
}

.story-text p div img {
  text-align: center;
}

.choices {
  padding: 0;
  margin-top: 1em;
  /* background: rgba(255, 255, 255, 0.9); */
  /* position: absolute; */
  /* left: 0; */
  /* right: 0; */
  /* bottom: 0; */
}

.choices .segment {
  padding: 0;
}

.choices .segment button {
  padding: 1em;
  width: 100%;
  border-radius: 0;
}

.ending {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
  font-size: 4em;
}
